<template>
    <vue-editor
      :style="styles"
      :placeholder="placeholder"
      :customModules="customModulesForEditor"
      :editorOptions="editorSettings"
      :editorToolbar="customToolbar"
      useCustomImageHandler
      @image-added="handleImageAdd"
      v-model="htmlSync"
    ></vue-editor>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";
import htmlEditButton from "quill-html-edit-button";
import {FileUpload } from "./quilFileUploader";
import {ImageUpload } from "./quilImageUploader";

const link = Quill.import("formats/link");
link.PROTOCOL_WHITELIST.push("data");

export default {
  name: "ContentsEditor",
  components: {
    VueEditor
  },
  props: {
    html: String,
    placeholder:String,
    styles:String
  },
  computed:{
    htmlSync: {
      get() {
        return this.html;
      },
      set(html) {
        this.$emit('sync', {html});
      }
    }
  },
  methods: {
    handleImageAdd(file, Editor, cursorLocation, resetUploader) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          Editor.insertEmbed(
            cursorLocation,
            "image",
            reader.result + "#" + encodeURIComponent(file.name)
          );
          resetUploader();
        },
        false
      );
      reader.readAsDataURL(file);
    },
  },
  data() {
    return {
      quill: null,
      output:null,
      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline"],
        [{ color: [] }, { background: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"]
      ],
      customModulesForEditor: [
        { alias: "imageDrop", module: ImageDrop },
        { alias: "imageResize", module: ImageResize },
        { alias: "fileUpload", module: FileUpload },
        { alias: "imageUpload", module: ImageUpload },
        { alias: "htmlEditButton", module: htmlEditButton },
      ],
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {},
          fileUpload: true,
          imageUpload: true,
          htmlEditButton: {},
        }
      }
    };
  }
};
</script>

export class ImageUpload {
    /**
     * Instantiate the module given a quill instance and any options
     * @param {Quill} quill
     * @param {Object} options
     */
    //eslint-disable-next-line
    constructor(quill, options = {}) {
      // save the quill reference
      this.quill = quill;
  
      // bind handlers to this instance
      const toolbar = quill.getModule("toolbar");
  
      const lastFormats = [
        ...toolbar.container.querySelectorAll(".ql-formats")
      ].pop();
      lastFormats.insertAdjacentHTML(
        "beforeEnd",
        `<button class="button ql-image" data-custom-image><i class="fas fa-images"></i></button>`
      );
      const customButton = lastFormats.querySelector("[data-custom-image]");
      customButton.addEventListener("click", evt => this.clickButton(evt), false);
    }
  
    /**
     * Handler for click event to toolbar button
     * @param {Event} evt
     */
    async clickButton(evt) {
      evt.preventDefault();
      const files = await new Promise(resolve => {
        const input = document.createElement("input");
        input.type = "file";
        input.setAttribute("multiple", true);
        input.onchange = event => {
          resolve(event.target.files);
        };
        input.click();
      });
      for(let i in files){
        const file = files[i];
        const dataUrl = await this.readFile(file);
        await this.insert(file.name, dataUrl);
      }
    }
    
    /**
     * Insert the image into the document at the current cursor position
     * @param {String} dataUrl  The base64-encoded image URI
     */
    async insert(filename, dataUrl) {
      let index =
        (this.quill.getSelection() || {}).index || this.quill.getLength();
      this.quill.insertEmbed(
        index,
        "image",
        dataUrl + "#" + encodeURIComponent(filename),
        "user"
      );
      index += 1;
      await new Promise(r => {
        setTimeout(() => {
            this.quill.setSelection(index, 0);
            r();
        }, 0)
      });

    }

  /**
   * Extract file URIs from evt.dataTransfer or evt.clipboardData
   * @param {File} file  One or more File objects
   */
  readFile(file) {
    return new Promise(resolve => {
      // set up file reader
      const reader = new FileReader();
      reader.onload = evt => {
        resolve(evt.target.result);
      };
      // read the clipboard item or file
      const blob = file.getAsFile ? file.getAsFile() : file;
      if (blob instanceof Blob) {
        reader.readAsDataURL(blob);
      }
    });
  }
}
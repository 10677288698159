export class FileUpload {
    /**
     * Instantiate the module given a quill instance and any options
     * @param {Quill} quill
     * @param {Object} options
     */
    //eslint-disable-next-line
    constructor(quill, options = {}) {
      // save the quill reference
      this.quill = quill;
  
      // bind handlers to this instance
      const toolbar = quill.getModule("toolbar");
  
      const lastFormats = [
        ...toolbar.container.querySelectorAll(".ql-formats")
      ].pop();
      lastFormats.insertAdjacentHTML(
        "beforeEnd",
        `<button class="button ql-file"><i class="fas fa-file-alt"></i></button>`
      );
      const customButton = lastFormats.querySelector(".ql-file");
      customButton.addEventListener("click", evt => this.clickButton(evt), false);
    }
  
    /**
     * Handler for click event to toolbar button
     * @param {Event} evt
     */
    async clickButton(evt) {
      evt.preventDefault();
      const files = await new Promise(resolve => {
        const input = document.createElement("input");
        input.type = "file";
        input.setAttribute("multiple", true);
        input.onchange = event => {
          resolve(event.target.files);
        };
        input.click();
      });
      for(let i in files){
        const file = files[i];
        const dataUrl = await this.readFile(file);
        await this.insert(file.name, dataUrl);
      }
    }
  
    /**
     * Handler for paste event to read pasted files from evt.clipboardData
     * @param {Event} evt
     */
    handlePaste(evt) {
      if (
        evt.clipboardData &&
        evt.clipboardData.items &&
        evt.clipboardData.items.length
      ) {
        const file = evt.clipboardData.items[0];
        const dataUrl = this.readFile(file);
        const selection = this.quill.getSelection();
        if (selection) {
          // we must be in a browser that supports pasting (like Firefox
          // so it has already been placed into the editor
        } else {
          // otherwise we wait until after the paste when this.quill.getSelection()
          // will return a valid index
          setTimeout(async () => await this.insert(file.name, dataUrl), 0);
        }
      }
    }
  
    /**
     * Insert the image into the document at the current cursor position
     * @param {String} dataUrl  The base64-encoded image URI
     */
    async insert(filename, dataUrl) {
      let index =
        (this.quill.getSelection() || {}).index || this.quill.getLength();
      this.quill.insertText(
        index,
        filename,
        "link",
        dataUrl + "#" + encodeURIComponent(filename),
        "user"
      );
      index += filename.length;
      this.quill.insertText(
        index,
        "\n"
      );
      // 改行のためindex調整
      index += 3
      await new Promise(r => {
        setTimeout(() => {
            this.quill.setSelection(index, 0);
            r();
        }, 0)
      });

    }

  /**
   * Extract file URIs from evt.dataTransfer or evt.clipboardData
   * @param {File} file  One or more File objects
   */
  readFile(file) {
    return new Promise(resolve => {
      // set up file reader
      const reader = new FileReader();
      reader.onload = evt => {
        resolve(evt.target.result);
      };
      // read the clipboard item or file
      const blob = file.getAsFile ? file.getAsFile() : file;
      if (blob instanceof Blob) {
        reader.readAsDataURL(blob);
      }
    });
  }
}